import { useRouter } from 'next/router';

import { CardProduct, SliderProduct } from '@/molecules';
import { Size, Variation } from '@/molecules/card-product/types';
import { ProductBlock } from '@/organisms';
import { FunctionComponent, useEffect } from 'react';

import { gtmPush, ordenarEtiquetas } from '@/utils';

import { TypeProductBlockShopifyProps } from './types';

/**
 * ProductBlockShopify
 */
export const ProductBlockShopify: FunctionComponent<
    TypeProductBlockShopifyProps
> = ({
    className = '',
    title,
    noColumnas = 4,
    shopifyCategory,
    shopifyProducts,
    cardVariation = Variation.DISPLAY,
}: TypeProductBlockShopifyProps) => {
        const { asPath } = useRouter();

        useEffect(() => {
            const elementos: {
                item_id: string;
                item_name: string;
                affiliation: string;
                currency: string;
                item_brand: string;
                item_category?: string; //Eliminar los voluntarios
                item_category2?: string; //Eliminar los voluntarios
                item_category3?: string; //Eliminar los voluntarios
                item_variant: string;
                price: string;
                quantity: number;
                index: number;
                item_list_name: string;
                discount?: number;
            }[] = [];
            shopifyProducts.filter((item: any) =>
                item.tags?.some((obj: any) => obj.name == shopifyCategory)
            ).map((item: any, index: number) => {
                const tags = item.tags.map((item) => (item.name));
                elementos.push({
                    item_id: item.product_id,
                    item_name: item.title.toUpperCase(),
                    affiliation: 'Navidul',
                    currency: 'EUR',
                    item_brand: 'Navidul',
                    item_variant: '',
                    price: item.variants[0].price,
                    item_category: ordenarEtiquetas(tags)[0][0]
                        ? ordenarEtiquetas(tags)[0][0]
                        : '',
                    item_category2: ordenarEtiquetas(tags)[0][1]
                        ? ordenarEtiquetas(tags)[0][1]
                        : '',
                    item_category3: ordenarEtiquetas(tags)[0][2]
                        ? ordenarEtiquetas(tags)[0][2]
                        : '',
                    discount: item?.variants[0].compare_at_price &&
                        item?.variants[0].price
                        ? item?.variants[0].compare_at_price -
                        item?.variants[0].price
                        : 0,
                    quantity: 1,
                    index: index,
                    item_list_name: `${title} - ${asPath != "/" ? asPath : 'Home'}`,

                });
            });
            //Mirar de mantener fuera del useEffect, construir el elementos a partir de mis productos, mantener el useEffect solo cuando se visite la página por primera vez
            if (elementos.length > 0) {
                gtmPush({ ecommerce: null });
                gtmPush({
                    event: 'view_item_list',
                    ecommerce: {
                        items: elementos,
                    },
                });
            }

        }, [asPath]);

        return (
            <ProductBlock title={title} className={className}>
                <SliderProduct
                    perPage={noColumnas ?? 4}
                    breakpoints={{
                        1024: {
                            perPage: noColumnas ?? 4,
                        },
                        768: {
                            padding: 45,
                            perPage: 2,
                        },
                        500: {
                            padding: 45,
                            perPage: 1,
                        },
                    }}
                >

                    {shopifyProducts.filter((item: any) =>
                        item.tags?.some((obj: any) => obj.name == shopifyCategory)
                    )
                        //         .sort((a: any, b: any) => {
                        //           const aOrden = parseInt(
                        //             a.node.metafields.nodes.find(
                        //               (n: any) => n.key === 'orden_productos'
                        //             )?.value
                        //           );
                        //           const bOrden = parseInt(
                        //             b.node.metafields.nodes.find(
                        //               (n: any) => n.key === 'orden_productos'
                        //             )?.value
                        //           );
                        //           return aOrden - bOrden;
                        //         })
                        .map((item: any, index: number) => {
                            const compareAtPrice =
                                item.variants[0].compare_at_price;
                            const price = item.variants[0].price;
                            const tagElement = item.tags.flatMap((item: any) => {
                                if (item.name == 'jamonero') {
                                    return (<CardProduct.Tag colors="info">
                                        con cuchillo y Jamonero
                                    </CardProduct.Tag>)
                                } else return []
                            })
                            return (
                                <CardProduct
                                    key={index}
                                    image={{ src: item.image.uri.url }}
                                    size={Size.MEDIUM}
                                    variation={cardVariation}
                                    href={item.path?.alias}
                                    itemListIndex={index}
                                    itemListName={title}
                                    inStock={item.variants[0].inventory_quantity > 0}
                                    product={item}
                                >
                                    {tagElement.length > 0 ? tagElement[0] : undefined}

                                    <CardProduct.Title>{item.field_alternate_title ? item.field_alternate_title.toUpperCase() : item.title.toUpperCase()}</CardProduct.Title>
                                    <CardProduct.Price
                                        regular={compareAtPrice || price}
                                        {...(compareAtPrice && { sale: price })}
                                    />
                                </CardProduct>
                            );
                        })
                    }
                </SliderProduct >
            </ProductBlock >
        );
    };

ProductBlockShopify.displayName = 'ProductBlockShopify';
